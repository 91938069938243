.anvil-show-banner{
    margin-top:0!important;
}
.header{
    display:flex;
    align-items:center;
    justify-content:space-around;
    height:52px;
    position:fixed;
    top:0;
    left:0;
    width:100%;
    background-color:#07294d;
}
.logo{
    height:45px;
}
.menu{
    display:flex;
    gap:30px;
}
.menu li{
    list-style:none;
    color:white;
}